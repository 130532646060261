<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <b-col md="3">
              <b-form-group style="padding: 10px;" :label="'Volume ('+filter[0].value+' - '+filter[1].value+' m3)'">
                <veeno v-if="volume_filter" connect @slide="setVolume" :step="5" :handles="[filter[0].value, filter[1].value]" :range ="{'min':0,'max':100}"/>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group style="padding: 10px;" :label="'Distance ('+filter[2].value+' - '+filter[3].value+' km)'">
                <veeno v-if="distance_filter" connect @slide="setDistance" :step="50" :handles="[filter[2].value, filter[3].value]" :range ="{'min':0,'max':1500}"/>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="filter[4].label+':'">
                <b-form-input v-model="filter[4].value" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="filter[5].label+':'">
                <b-form-input v-model="filter[5].value" required></b-form-input>
              </b-form-group>
            </b-col>
          </div>
          <div class="row">
            <div class="offset-md-5 col-md-4">
              <button type="button" class="btn btn-primary mr-1 mb-1 waves-effect waves-light" @click="datatableInit(true)">{{$store.back.getText('recherchez')}}</button>
              <button type="button" class="btn btn-danger mr-1 mb-1 waves-effect waves-light" @click="searchReset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col :md="lotSelected.id === null ? 12 : 7">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('liste_lots_dispo')}}</h3>
            </div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="1">
                <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </b-col>
            </b-row>
            <b-table @row-clicked="changeLot" responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
              <!--template #cell(id)="data">
                <a :href="'/#/propriete/detail/'+data.value">{{ data.value }}</a>
              </template-->
            </b-table>
            <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
          </div>
        </div>
      </b-col>
      <b-col md="5" v-if="lotSelected.id != null">
        <div class="card mt-3">
          <div class="card-header">
            <div class="text-center col-12" v-if="lotSelected.groupe_id != null">
              <div class="dropdown" style="z-index:1030" :set="groupe = groupes.find(g => g.id == lotSelected.groupe_id)">
                <button class="btn btn-danger" :href="'tel:'+groupe.telephone"><i class="fa fa-phone"></i>{{groupe.telephone}}</button>
              </div>
            </div>
            <div class="text-center col-12 mt-1">
              <div class="dropdown" style="z-index:1030">
                <button class="btn btn-success mr-1" v-b-modal.addLot v-if="datatable.meta.docs">{{$store.back.getText('prendre_lot')}}</button>
                <b-dropdown :text="$store.back.getText('documents')" variant="primary" class="mr-1">
                  <b-dropdown-item :href="'https://api.get-move.com/v1/inventaire/pdf/'+lotSelected.detail.numero_cli" target="_blank">Inventaire</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <Map :waypoints="waypoints" />
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_dem')}}</h4>
              <div class="form " id="infoDem">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date_charge')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.date_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cubage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.cubage" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_presta')}}</label>
                        <select type="text" class="form-control" v-model="lotSelected.detail.categorie" disabled>
                          <option value="1">{{$store.back.getText('categorie')}} {{lotSelected.detail.categorie}}</option>
                          <option value="2">{{$store.back.getText('categorie')}} {{lotSelected.detail.categorie}}</option>
                          <option value="3">{{$store.back.getText('categorie')}} {{lotSelected.detail.categorie}}</option>
                          <option value="4">{{$store.back.getText('categorie')}} {{lotSelected.detail.categorie}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_transport')}}</label>
                        <input v-if="lotSelected.detail.distance > 200" type="text" class="form-control"  disabled>
                        <input v-else type="text" class="form-control" :value="$store.back.getText('urbain')" disabled>
                      </div>
                    </div><div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obj_lourds')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.objet_lourd" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('prix_ttc')}}</label>
                        <input type="text" class="form-control" :value="getPrice(lotSelected,true,false)" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_dem')}}</label>
                        <select type="text" class="form-control" v-model="lotSelected.detail.type_lot" disabled>
                          <option value="1">{{$store.back.getText('dem_parti')}}</option>
                          <option value="2">{{$store.back.getText('dem_ste')}}</option>
                          <option value="3">{{$store.back.getText('dem_inter')}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('type_livraison')}}</label>
                        <select type="text" class="form-control"  v-model="lotSelected.detail.type_livraison" disabled>
                          <option value="1">{{$store.back.getText('livraison_date_fix')}}</option>
                          <option value="2">{{$store.back.getText('livrason_jour')}}</option>
                          <option value="3">{{$store.back.getText('livraison_suivant')}}</option>
                          <option value="4">{{$store.back.getText('livraison_groupage')}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('val_assur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.montant_assurer" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('materiel_prev')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.materiel_aprevoir" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_depart')}}</h4>
              <form class="form " id="InfoDepart">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.date_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cp')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.cp_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('etage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.etage_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('monte_meuble')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.monte_meuble_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('portage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.portage_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ast')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.autorisationstationnement_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('tranche_date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.periode_flexible_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ville')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.ville_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ascenseur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.ascenceur_util_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('passage_fenetre')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.passage_fen_depart" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('transbordement')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.transbordement_depart" disabled>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obser')}}</label>
                        <textarea class="form-control" v-model="lotSelected.detail.commentaire_depart" disabled rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-content">
            <div class="card-body">
              <h4 class="card-title">{{$store.back.getText('info_arrivee')}}</h4>
              <form class="form " id="InfoArr">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.date_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('cp')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.cp_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('etage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.etage_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('mt')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.monte_meub_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('portage')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.portage_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ast')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.autorisationstationnement_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('tranche_date')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.periode_flexible_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ville')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.ville_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('ascenseur')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.ascenceur_util_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('passage_fenetre')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.passage_fen_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('transbordement')}}</label>
                        <input type="text" class="form-control" v-model="lotSelected.detail.transbordement_arrivee" disabled>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{$store.back.getText('obser')}}</label>
                        <textarea class="form-control" v-model="lotSelected.detail.commentaire_arrivee" disabled rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <button class="btn btn-danger btn-block" @click="lotSelected.id = null"><i class="fa fa-arrow-left"></i></button>
      </b-col>
    </b-row>
    <b-modal id="addLot" :title="$store.back.getText('prendre_lot')" ref="addLot">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{$store.back.getText("date_propose")}}</label>
              <b-form-datepicker v-model="preneur.date"></b-form-datepicker>
            </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>{{$store.back.getText("prix_propose")}} (€)</label>
              <input v-model.number="preneur.prix" type="number" class="form-control">
            </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addPreneur()">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import veeno from 'veeno';
import 'nouislider/distribute/nouislider.min.css';
import Map from '@/components/Map'

export default {
  data(){
    return {
      volume_filter:true,
      distance_filter:true,
      items: [],
      filter:[
        {
            "key":"min_lot.cubage",
            "label":"Volume minimum",
            "type":"Integer",
            "value":0
        },
        {
            "key":"max_lot.cubage",
            "label":"Volume maximum",
            "type":"Integer",
            "value":100
        },
        {
            "key":"min_lot.distance",
            "label":"Distance minimum",
            "type":"Integer",
            "value":0
        },
        {
            "key":"max_lot.distance",
            "label":"Distance maximum",
            "type":"Integer",
            "value":1500
        },
        {
            "key":"lot.cp_depart",
            "label":"Departement de depart",
            "type":"String",
            "value":null
        },
        {
            "key":"id",
            "label":"Ref. Lot",
            "type":"Integer",
            "value":null
        }
      ],
      fields:[
        {
          key:"id",
          label: "Ref",
          sortable:true,
          searchable:false

        },
        {
          key:"lot.date_depart",
          label:"Date depart",
          sortable:true,
          searchable:false
        },
        {
          key:"lot.periode_flexible_depart",
          label:"Date flexible",
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cp_depart",
          label:this.$store.back.getText('cp_depart'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cp_arrivee",
          label:this.$store.back.getText('cp_livraison'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.distance",
          label:this.$store.back.getText('distance'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.cubage",
          label:this.$store.back.getText('volume'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.categorie",
          label:this.$store.back.getText('categorie'),
          sortable:true,
          searchable:false
        },
        {
          key:"lot.prix_dailycall_ht",
          label:'Prix TTC',
          sortable:true,
          searchable:false
        }
      ],
      datatable:{
        currentPage: 1,
        data:[],
        limitPage: 20,
        nextPage: 0,
        previousPage: 0
      },
      sort:{
        key: 'date_depart',
        value: 'ASC'
      },
      lotSelected:{
        id: null,
        lot: {
          categorie: null,
          cp_arrivee: null,
          cp_depart: null,
          cubage: null,
          date_depart: null,
          distance: null,
          pays_depart: null,
          periode_flexible_depart: null,
          prix_dailycall_ht: null
        }
      },
      map:null,
      preneur: {
        date:null,
        prix:null,
      },
      groupes:[],

    };
  },
  components: {
    veeno,
    Map
  },
  watch: {
  },
  beforeMount() {
    this.$store.back.ajax('/market/groupes', null, (res) => {
      if(res.status === true){
        this.groupes = res.data.liste;
      }
    });
  },
  methods: {
    datatableInit: function(){
      var params = {
        filter: this.filter,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/market/lots/disponible', params, (res) => {
        this.datatable = res;
        this.datatable.meta.docs = true
      });
    },
    sortChanged(e){
      this.sort = {
        key: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    searchReset(){
      this.filter[0].value = 0;
      this.filter[1].value = 100;
      this.filter[2].value = 0;
      this.filter[3].value = 1500;
      this.filter[4].value = null;
      this.filter[5].value = null;
      this.datatableInit();
      this.volume_filter = false;
      this.distance_filter = false;
      setTimeout(() => {
        this.volume_filter = true;
        this.distance_filter = true;
      },500);
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    setVolume(e){
      this.filter[0].value = parseInt(e.values[0])
      this.filter[1].value = parseInt(e.values[1])
    },
    setDistance(e){
      this.filter[2].value = parseInt(e.values[0])
      this.filter[3].value = parseInt(e.values[1]);
    },
    changeLot(row){
      this.lotSelected.id = null;
      setTimeout(() => {
        var t = JSON.stringify(row);
        this.lotSelected = JSON.parse(t);
      },250);
    },
    getPrice(row,tva = false,formule = false){
      var franchise = 0;
      var franchisemax = 0;
      if(row.lot.suppfranch == "on"){
        var assurance = ( parseFloat(row.lot.montant_assurer)*0.6) /100;
        franchisemax = (parseInt(row.lot.prix_dailycall_ht)*8)/100;
        if(franchisemax < assurance ){
          franchise = franchisemax;
        }else{
          franchise = assurance;
        }
      }
      if(formule === true){
        return franchise;
      }
      var supplement = row.lot.supplement === "" || row.lot.supplement === null ? 0 : parseFloat(row.lot.supplement);
      var priceHT = parseFloat(row.lot.prix_dailycall_ht) - parseInt(franchise) + supplement;
      if(tva === false){
        return priceHT.toFixed(2)
      }else{
        if(row.lot.exo_tva == "on"){
          return priceHT.toFixed(2);
        }else{
          var ratioTVA = (100+row.secteur.tva)/100;
          return (priceHT*ratioTVA).toFixed(2);
        }
      }
    },
    addPreneur(){
      var date = null;
      if(this.preneur.date != null){
        date = this.preneur.date.split("-").reverse().join("/")
      }
      var preneur = {
        prix: this.preneur.prix,
        date: date,
        id_lot:this.lotSelected.id
      }
      this.$store.back.ajax('/market/preneur',preneur, (res) => {
        if(res.status === true){
          switch(res.data){
            case 1:
            this.$bvToast.toast(this.$store.back.getText('non_dispo'), {
              title: 'Erreur !',
              variant: 'danger',
              solid: true
            });
            break;
            case 2:
            this.$bvToast.toast(this.$store.back.getText('position_valide'), {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
            this.lotSelected.id = null;
            this.datatableInit();
            this.$refs.addLot.hide();
            break;
            case 3:
            this.$bvToast.toast(this.$store.back.getText('position_error'), {
              title: 'Erreur !',
              variant: 'danger',
              solid: true
            });
            break;
          }
        }
      })
    },
  },
  computed: {
    waypoints(){
      if(this.lotSelected.detail.lon_a === null || this.lotSelected.detail.lon_d === null){
        this.$bvToast.toast(this.$store.back.getText('merci_renseigner'), {
          title: 'Erreur !',
          variant: 'danger',
          solid: true
        });
        return false
      }
      return [
        { lat: this.lotSelected.detail.lat_d, lng: this.lotSelected.detail.lon_d },
        { lat: this.lotSelected.detail.lat_a, lng: this.lotSelected.detail.lon_a }
      ];
    },
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
